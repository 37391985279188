/* eslint-disable react/prop-types */
import React from 'react';
import dataSuccess from 'data/dataSuccess';
import Amount from 'components/Amount';
import SuccessFile from 'components/success/SuccessFile';
import CommentBlock from 'components/success/CommentBlock';
import SuccessParagraph from 'components/success/SuccessParagraph';
import SuccessPage from 'components/success/SuccessPage';
import PrimeBlock from 'components/success/PrimeBlock';
import RenoPrimeBlock from 'components/success/RenoPrimeBlock';
import AccompagnateurRenoBlock from './AccompagnateurRenoBlock';

class SuccessNormal extends React.Component {
  render() {
    const APIresult = this.props.result.data;
    const infoReno = APIresult['Information Rénovation globale'];
    const isRenoGlobale = this.props.answers.Q1 === 'renovationGlobale';
    const price1 = this.props.answers.Q43
      ? this.props.answers.Q43
      : isRenoGlobale && this.props?.renoTagOffer?.montant_travaux
        ? this.props.renoTagOffer.montant_travaux
        : APIresult['Coût travaux unitaire (€)'];
    const priceAcompagnateurRenov =
      APIresult['Montant mon accompagnateur renov (€)'];
    const price3 = APIresult['Reste à charge unitaire (€)']
      ? APIresult['Reste à charge unitaire (€)']
      : 0;
    const nonPrimeHellio = !APIresult['Montant prime CEE unitaire (€)'];
    const isGoodEnergyTag =
      this.props.answers.Q53 === 'A' || this.props.answers.Q53 === 'B';
    const energyTagIsUnknown = this.props.answers.Q53 === 'Je ne sais pas';
    const energyTag = energyTagIsUnknown
      ? APIresult['DPE estimé']
      : this.props.answers.Q53;
    const isAudit =
      isRenoGlobale &&
      (this.props.success === 'AuditEnergetique' ||
        this.props.success === 'RenovationGlobale');
    const needsRenovation = isRenoGlobale ? infoReno && !isGoodEnergyTag : true;
    const auditDone = this.props.answers.Q14 === 'Oui';

    // Aucune étiquette fournie pour une rénovation globale
    if (!energyTag && isRenoGlobale && !isAudit) {
      return <SuccessFile {...this.props} />;
    }

    return (
      <div className={isRenoGlobale && 'prime-small-text'}>
        <SuccessPage
          {...this.props}
          comment={
            needsRenovation && (
              <>
                {this.props.answers.Q50 === 'Non renseigné' &&
                  this.props.answers.Q51 === 'Je ne sais pas' && ( // Type ou usage de PAC non renseigné
                    <SuccessParagraph>
                      Les coûts de travaux et aides sont des estimations sur
                      principe qu’une Pompe à chaleur Air/eau à usage du
                      chauffage et eau chaude sanitaire a été installée.
                    </SuccessParagraph>
                  )}
                {!this.props.isPrime && !nonPrimeHellio && !isRenoGlobale && (
                  <SuccessParagraph>
                    {dataSuccess[this.props.success].paragraph} <br />
                    <span className="is-bold">
                      {dataSuccess[this.props.success].paragraphBold}
                    </span>
                  </SuccessParagraph>
                )}
                {nonPrimeHellio && !isRenoGlobale && (
                  <SuccessParagraph>
                    La prime Hellio n&apos;est éligible qu&apos;en cas de
                    remplacement de chaudière
                  </SuccessParagraph>
                )}
                {this.props.isPrime && !isRenoGlobale && (
                  <SuccessParagraph>
                    Ces montants de primes sont des estimations, les aides
                    financières sont susceptibles de varier selon le type de
                    matériel installé. Hellio confirmera votre montant de prime
                    avec votre devis et votre revenu fiscal de référence validés
                  </SuccessParagraph>
                )}
                {isRenoGlobale && (
                  <>
                    {!isAudit && (
                      <h5 className="text-main mb-5">
                        <span className="font-semibold">
                          Hellio s’occupe de toutes vos démarches
                          administratives.
                        </span>
                      </h5>
                    )}
                    <SuccessParagraph>
                      {isAudit ? (
                        <>
                          Pour la rénovation d’ampleur vous devez d’abord
                          réaliser un audit énergétique de votre logement.
                        </>
                      ) : (
                        <>
                          <sup>*</sup>Pour réaliser une rénovation
                          d&apos;ampleur vous devez réaliser au minimum 2 gestes
                          d&apos;isolation.
                          <br />
                          <br />
                          <sup>*</sup>Les prix de Mon Accompagnateur Rénov’
                          peuvent varier en fonction de la surface de votre
                          logement et de votre code postal.
                        </>
                      )}
                    </SuccessParagraph>
                  </>
                )}
              </>
            )
          }
        >
          {needsRenovation && (
            <>
              <div>
                {this.props.showAuditWarning && (
                  <div>
                    {!auditDone && (
                      <div className="py-3">
                        <h3 className="text-main text-xs">
                          Pour réaliser une rénovation d’ampleur, un audit
                          énergétique est obligatoire.
                        </h3>
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={`success-item-right ${isRenoGlobale ? 'last' : ''}`}
                >
                  <h5>
                    {isAudit
                      ? 'Coût de l’audit énergétique'
                      : 'Estimation des coûts de travaux'}
                  </h5>
                  <div>
                    <h5
                      className={
                        isAudit
                          ? 'h5-price whitespace-nowrap text-main'
                          : 'h5-price whitespace-nowrap'
                      }
                    >
                      <Amount value={price1} />
                    </h5>
                  </div>
                </div>
                {isRenoGlobale && (
                  <div className="success-item-right">
                    <h5>
                      Mon Accompagnateur Rénov&apos;
                      <div className="text-xs text-light">
                        {!auditDone && 'Avec audit énergétique'}
                      </div>
                    </h5>
                    <div>
                      <h5 className="h5-price whitespace-nowrap">
                        <Amount value={priceAcompagnateurRenov} />
                      </h5>
                    </div>
                  </div>
                )}
              </div>
              {this.props.renoTagOffer && isRenoGlobale && !isAudit && (
                <RenoPrimeBlock
                  APIresult={APIresult}
                  success={this.props.success}
                  resultCodePromo={this.props.resultCodePromo}
                  energyTag={energyTag}
                  renoTagOffer={this.props.renoTagOffer}
                  handleRenoTagOffer={this.props.handleRenoTagOffer}
                  montantAccompagnateurRenov={priceAcompagnateurRenov}
                ></RenoPrimeBlock>
              )}
              <div className="pt-3">
                <AccompagnateurRenoBlock
                  mar={APIresult['Prime MAR']}
                  isAudit={isAudit}
                  isRenoGlobale={isRenoGlobale}
                  renoTagOffer={this.props.renoTagOffer}
                  APIresult={APIresult}
                />
              </div>
              {!!price3 && price3 !== price1 && (
                <div className="pt-5">
                  {!isRenoGlobale && (
                    <PrimeBlock
                      APIresult={APIresult}
                      success={this.props.success}
                      resultCodePromo={this.props.resultCodePromo}
                    ></PrimeBlock>
                  )}

                  <div className="success-item-right last">
                    <h5>Reste à payer</h5>
                    <div>
                      <h5 className="h5-price whitespace-nowrap">
                        <Amount
                          value={
                            this.props.resultCodePromo.data.reste_a_charge
                              ? this.props.resultCodePromo.data.reste_a_charge
                              : price3
                          }
                        />
                      </h5>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {!needsRenovation && (
            <CommentBlock>
              <>
                Votre logement ne nécessite pas de rénovation d’ampleur. Pour
                voir toutes nos offres de rénovation énergétique, rendez-vous
                sur notre site{' '}
                <a
                  href="https://particulier.hellio.com/"
                  rel="noreferrer"
                  target="_blank"
                  className="ext-link"
                >
                  particulier.hellio.com
                </a>
                .
              </>
            </CommentBlock>
          )}
        </SuccessPage>
      </div>
    );
  }
}

export default SuccessNormal;
