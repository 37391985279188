const token = process.env.REACT_APP_HELLIO_API_TOKEN;
const apiNames = {
  'hellio-api': process.env.REACT_APP_HELLIO_API_URL,
};

export default class http {
  static async post(url, body) {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(url, options);

      if (response.ok) {
        return response.json();
      }

      if ([400, 401, 500].includes(response.status)) {
        const data = response.json();
        throw new Error(data.errors);
      }
    } catch (err) {
      console.error(`Error occur when fetching data from ${url}:`, err);
    }
  }

  static getApiUrl(apiName, endpoint) {
    let url = apiNames[apiName];

    if (!url) {
      return '';
    }

    if (endpoint) {
      url = url + `/${endpoint}`
    }

    return url;
  }
}
