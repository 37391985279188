import OfferResultBlock from './OfferResultBlock';
import OfferSwitcher from './OfferSwitcher';
import PowerPriceCalculator from './PowerPriceCalculator';

const offerKeys = {
  Autoconsommation: 'Autoconsommation',
  BatterieVirtuelle: 'Batterie_virtuelle',
};

export { OfferResultBlock, OfferSwitcher, PowerPriceCalculator, offerKeys };
