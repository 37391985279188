import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import * as Sentry from '@sentry/react';

const sentryEnabled = !process.env.REACT_APP_DISABLE_SENTRY;

// Init Sentry
Sentry.init({
  dsn: sentryEnabled
    ? 'https://326262e7e6785a73242156e1eab069b3@o4508086168125440.ingest.de.sentry.io/4508381898211408'
    : undefined,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'https://simulateur.hellio.com/',
    'https://staging.hubspot-react.pages.dev/',
    'https://dev.hubspot-react.pages.dev/',
    'https://hubspot-react.pages.dev/',
  ],
  // Session Replay config
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// import your route components too
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Routes>
      <Route index element={<App />} />
      <Route path="*" element={<App />}></Route>
      {/* <Route path="*" element={<App id='Q1' />} /> */}
    </Routes>
  </BrowserRouter>,
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
