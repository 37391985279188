import React from 'react';
// import iconCombles from '../images/icons/success/combles.svg';
// import iconITI from '../images/icons/success/ITI.svg';
// import iconGarage from '../images/icons/success/garage.svg';
// import iconChauffageGaz from '../images/icons/success/chauffageGaz.svg';
// import iconChauffageAutre from '../images/icons/success/chauffageAutre.svg';
// import iconChauffageBiomasse from '../images/icons/success/chauffageBiomasse.svg';
// import iconChauffagePac from '../images/icons/success/chauffagePac.svg';
// import iconChauffagePoele from '../images/icons/success/chauffagePoele.svg';
// import iconBorneDeRecharge from '../images/icons/success/borneDeRecharge.svg';
// import iconPhotovoltaique from '../images/icons/success/photovoltaique.svg';
// import iconRenovationGlobale from '../images/icons/success/renovationGlobale.svg';
// import iconAuditEnergetique from '../images/icons/success/auditEnergetique.svg';
import dataSuccessIcons from '../data/dataSuccessIcons';
import { PrimeSuspendue } from 'components/PrimeSuspendue';

const ComblesShare = {
  imgDesktop: 'https://hellio.com/hubfs/simulateur/img_success2/combles.png',
  imgMobile: 'https://hellio.com/hubfs/simulateur/img_success2/combles-m.png',
  icon: dataSuccessIcons.iconCombles,
  h1: 'Isolation des combles',
};

const CoproShare = {
  imgDesktop: 'https://hellio.com/hubfs/simulateur/img_success2/copro.png',
  imgMobile: 'https://hellio.com/hubfs/simulateur/img_success2/copro-m.png',
  h1: 'Votre projet en copropriété',
  h2: '',
  paragraph:
    'Votre inscription est bien prise en compte. Nos experts Hellio se chargent de contacter votre syndic afin de présenter les différentes opportunités de rénovation énergétique pour votre copropriété.',
  template: 'copro',
  simulation: false,
};

const chauffageEauShare = {
  imgDesktop:
    'https://hellio.com/hubfs/simulateur/img_success2/chauffageEau.png',
  imgMobile:
    'https://hellio.com/hubfs/simulateur/img_success2/chauffageEau-m.png',
  template: 'normal',
  paragraph:
    'Ces montants de primes sont des estimations, les aides financières sont susceptibles de varier selon le type de matériel installé. Hellio confirmera votre montant de prime avec votre devis et votre revenu fiscal de référence validés',
  simulation: true,
};

const PACshare = {
  imgDesktop:
    'https://hellio.com/hubfs/simulateur/img_success2/chauffagePac.png',
  imgMobile:
    'https://hellio.com/hubfs/simulateur/img_success2/chauffagePac-m.png',
  template: 'normal',
  simulation: true,
};

const paragraph =
  "Ce prix inclut la fourniture et la pose d'isolant.  Les montants de travaux et de primes peuvent varier en fonction de la surface chauffée, de la zone géographique du chantier, ou des revenus du ménage. Un artisan partenaire RGE précisera ces estimations ainsi que la faisabilité technique du chantier lors d'une visite technique gratuite et sans engagement.";
const paragraphChauffage =
  "Ce prix inclut la dépose de l'ancienne chaudière, la fourniture et la pose de la nouvelle chaudière ainsi que la mise en conformité de la chaufferie.  Les montants de travaux et de primes peuvent varier en fonction de la surface chauffée, de la zone géographique du chantier, ou des revenus du ménage. Un artisan partenaire RGE précisera ces estimations ainsi que la faisabilité technique du chantier lors d'une visite technique gratuite et sans engagement.";
const paragraphBorne =
  'Le montant est susceptible de varier en fonction de la distance qui sépare la borne du compteur.';
const paragraphPhoto =
  "*Les prix affichés correspondent au montant des travaux, prime à l’autoconsommation déduite. ils sont indicatifs et seront confirmés ou modifiés à la suite d'une visite technique réalisée par notre artisan RGE QualiPV.";
const paragraphAudit =
  "Ce prix inclut un audit énergétique complet de votre logement. Notre bureau d'études vous livre une étude complète ainsi que 2 scénarios de travaux de rénovation énergétique.";
const paragraphITE =
  "Ce prix inclut la fourniture et la pose d'isolant.  Les montants de travaux et de primes peuvent varier en fonction de la surface chauffée, de la zone géographique du chantier, ou des revenus du ménage. Un artisan partenaire RGE précisera ces estimations ainsi que la faisabilité technique du chantier lors d'une visite technique gratuite et sans engagement. Ce prix n’inclut pas les points singuliers d’un chantier.";
const paragraphPAC =
  'Ces montants de primes sont des estimations, les aides financières sont susceptibles de varier selon le type de matériel installé. Hellio confirmera votre montant de prime avec votre devis et votre revenu fiscal de référence validés.';
const dataSuccess = {
  ComblesPerdus: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/comblesPerdus.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/comblesPerdus-m.png',
    icon: dataSuccessIcons.iconCombles,
    h1: 'Isolation des combles',
    template: 'normal',
    h2: 'Isolation des combles perdus',
    paragraph,
    simulation: true,
    showM2: false,
  },
  ComblesAmenages: {
    ...ComblesShare,
    template: 'normal',
    h2: 'Isolation des combles aménagés',
    paragraph,
    simulation: true,
    showM2: false,
  },
  ComblesNonRenseigne: {
    ...ComblesShare,
    template: 'comment',
    h3: 'Pour calculer les coûts de travaux et les aides de ce projet, un appel avec un conseiller Hellio est nécessaire. Nous vous rappelerons dans les plus brefs délais. ',
    simulation: true,
    showM2: false,
  },
  ITI: {
    imgDesktop: 'https://hellio.com/hubfs/simulateur/img_success2/ITI.png',
    imgMobile: 'https://hellio.com/hubfs/simulateur/img_success2/ITI-m.png',
    icon: dataSuccessIcons.iconITI,
    h1: 'Isolation des murs',
    template: 'normal',
    h2: 'Isolation des murs par l’intérieur',
    paragraph,
    simulation: true,
    showM2: false,
  },
  ITE: {
    imgDesktop: 'https://hellio.com/hubfs/simulateur/img_success2/ITE.png',
    imgMobile: 'https://hellio.com/hubfs/simulateur/img_success2/ITE-m.png',
    icon: dataSuccessIcons.iconCombles,
    h1: 'Isolation des murs',
    template: 'normal',
    h2: 'Isolation des murs par l’extérieur',
    paragraph: paragraphITE,
    paragraphBold:
      "Attention : MaPrimeRénov' ne s'applique que pour les 100 premiers m2 de votre chantier pour l'isolation des mûrs par l'extérieur",
    simulation: true,
    showM2: false,
  },

  Plancher: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/isolation.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/isolation-m.png',
    icon: dataSuccessIcons.iconGarage,
    h1: 'Isolation d’un garage ou sous-sol',
    h2: 'Isolation d’un garage ou sous-sol',
    vide: 'Isolation d’un vide sanitaire',
    garage: 'Isolation d’un garage ou sous-sol',
    template: 'normal',
    paragraph,
    simulation: true,
    showM2: false,
  },
  chauffageGaz: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/chauffageGaz.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/chauffageGaz-m.png',
    icon: dataSuccessIcons.iconChauffageGaz,
    h1: 'Changement de chauffage',
    h2: 'Installation d’une ',
    template: 'normal',
    paragraph: paragraphChauffage,
    simulation: true,
  },
  chauffagePac: {
    ...PACshare,
    h1: 'Changement de chauffage',
    h2: "Installation d'une",
  },
  chauffagePoele: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/chauffagePoele.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/chauffagePoele-m.png',
    icon: dataSuccessIcons.iconChauffagePoele,
    h1: 'Changement de chauffage',
    h2: 'Installation d’un poêle à bois',
    template: 'normal',
    simulation: true,
  },
  chauffageBiomasse: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/chauffageBiomasse.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/chauffageBiomasse-m.png',
    icon: dataSuccessIcons.iconChauffageBiomasse,
    h1: 'Changement de chauffage',
    h2: 'Installation d’une chaudière biomasse (granulés de bois)',
    template: 'normal',
    paragraph: paragraphChauffage,
    simulation: true,
  },
  ChauffageAutre: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/chauffageGaz.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/chauffageGaz-m.png',
    icon: dataSuccessIcons.iconChauffageAutre,
    h1: 'Changement de chauffage',
    h2: '',
    h3: 'Pour calculer les coûts de travaux et les aides de ce projet, un appel avec un conseiller Hellio est nécessaire. Nous vous rappelerons dans les plus brefs délais. ',
    template: 'comment',
    simulation: true,
  },

  borneDeRecharge: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/borneDeRecharge.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/borneDeRecharge-m.png',
    icon: dataSuccessIcons.iconBorneDeRecharge,
    h1: 'Installation d’une borne de recharge',
    h2: 'Borne Hager Witty 7 kWh',
    h3: 'Pour calculer les coûts de travaux et les aides de ce projet, un appel avec un conseiller Hellio est nécessaire. Nous vous rappelerons dans les plus brefs délais. ',
    paragraph: paragraphBorne,
    template: 'borne',
    simulation: true,
  },

  photovoltaïque: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/photovoltaique.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/photovoltaique-m.png',
    icon: dataSuccessIcons.iconPhotovoltaique,
    h1: 'Installation de panneaux solaires',
    h2: '',
    paragraph: paragraphPhoto,
    template: 'photovoltaique',
    simulation: true,
  },

  photovoltaïqueSolaireMax: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/photovoltaiqueMax.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/photovoltaiqueMax-m.png',
    icon: dataSuccessIcons.iconPhotovoltaique,
    h1: 'Nouvelle offre SolaireMAX',
    h2: '',
    paragraph: paragraphPhoto,
    template: 'photovoltaique',
    simulation: true,
  },

  RenovationGlobalesAuditDone: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/RenovationGlobale.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/RenovationGlobale-m.png',
    icon: dataSuccessIcons.iconRenovationGlobale,
    h1: 'Rénovation d’ampleur',
    paragraph:
      'Pour calculer les coûts de travaux et les aides de ce projet, un appel avec un conseiller Hellio est nécessaire. Nous vous rappelerons dans les plus brefs délais.',
    template: 'normal',
    simulation: true,
  },
  RenovationGlobale: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/RenovationGlobale.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/RenovationGlobale-m.png',
    icon: dataSuccessIcons.iconRenovationGlobale,
    h1: 'Audit énergétique',
    h2: '',
    paragraph:
      'Pour la rénovation d’ampleur vous devez d’abord réaliser un audit énergétique de votre logement.',
    paragraphLeft:
      'Pour réaliser une rénovation d’ampleur, vous devez réaliser un audit énergétique au préalable.',
    template: 'normal',
    simulation: true,
  },
  AuditEnergetique: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/AuditEnergetique.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/AuditEnergetique-m.png',
    icon: dataSuccessIcons.iconAuditEnergetique,
    h1: 'Audit énergétique',
    h2: 'Audit énergétique',
    h3: 'Pour calculer les coûts de travaux et les aides de ce projet, un appel avec un conseiller Hellio est nécessaire. Nous vous rappelerons dans les plus brefs délais. ',
    paragraph: paragraphAudit,
    template: 'audit',
    simulation: true,
  },
  'audit énergétique': {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/AuditEnergetique.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/AuditEnergetique-m.png',
    icon: dataSuccessIcons.iconAuditEnergetique,
    h1: 'Audit énergétique',
    h2: 'Audit énergétique',
    h3: 'Pour calculer les coûts de travaux et les aides de ce projet, un appel avec un conseiller Hellio est nécessaire. Nous vous rappelerons dans les plus brefs délais. ',
    paragraph: paragraphAudit,
    template: 'audit',
    simulation: true,
  },

  nonRenseigne: {
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/RenovationGlobale.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/RenovationGlobale-m.png',
    icon: dataSuccessIcons.iconRenovationGlobale,
    h1: 'Vous souhaitez être conseillé ? ',
    h2: '',
    h3: 'Pour calculer les coûts de travaux et les aides de ce projet, un appel avec un conseiller Hellio est nécessaire. Nous vous rappelerons dans les plus brefs délais.',
    template: 'comment',
    simulation: false,
  },

  chauffagePacEauEau: {
    ...PACshare,
    h1: 'Changement de chauffage',
    h2: "Installation d'une pompe à chaleur eau/eau",
    paragraph: paragraphPAC,
  },

  chauffagePacHybrideAirEau: {
    ...PACshare,
    h1: 'Changement de chauffage',
    h2: "Installation d'une pompe à chaleur hybride air/eau",
    paragraph: paragraphPAC,
  },

  ChauffeEauSolaire: {
    ...chauffageEauShare,
    h1: "Installation d'un chauffe-eau solaire",
  },

  ChauffeEauThermodynamique: {
    ...chauffageEauShare,
    h1: "Installation d'un chauffe-eau thermodynamique",
  },

  VMCDoubleFlux: {
    h1: "Installation d'une VMC double flux",
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/ventilation.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/ventilation-m.png',
    template: 'normal',
    paragraph: paragraphPAC,
    simulation: true,
  },
  VMCSimpleFlux: {
    h1: "Installation d'une VMC simple flux",
    h2: '',
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/ventilation.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/ventilation-m.png',
    template: 'normal',
    paragraph: paragraphPAC,
    simulation: true,
  },
  chauffagePoeleBois: {
    h1: 'Changement de chauffage',
    h2: "Installation d'un poêle à bois",
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/poeleABois.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/poeleABois-m.png',
    template: 'normal',
    paragraph: paragraphPAC,
    simulation: true,
  },
  chauffagePoeleGranule: {
    h1: 'Changement de chauffage',
    h2: "Installation d'un poêle à granulé de bois",
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/poeleAGranule.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/poeleAGranule-m.png',
    template: 'normal',
    paragraph: paragraphPAC,
    simulation: true,
  },
  reseauDeChaleur: {
    h1: 'Changement de chauffage',
    h2: 'Raccordement à un réseau <br/>de chaleur',
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/reseauChaleur.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/reseauChaleur-m.png',
    template: 'normal',
    paragraph: paragraphPAC,
    simulation: true,
  },
  systemeSolaireCombine: {
    h1: 'Changement de chauffage',
    h2: "Installation d'un système <br>solaire combiné",
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/systemSolaire.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/systemSolaire-m.png',
    template: 'normal',
    paragraph: paragraphPAC,
    simulation: true,
  },
  Fenetres: {
    h1: 'Fenêtres de toitures',
    imgDesktop: 'https://hellio.com/hubfs/simulateur/img_success2/Fenetres.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/Fenetres-m.png',
    template: 'normal',
    paragraph: paragraphPAC,
    simulation: true,
  },
  PortesFenetres: {
    h1: 'Fenêtres ou Portes-fenêtres complètes avec vitrage isolant',
    imgDesktop:
      'https://hellio.com/hubfs/simulateur/img_success2/porteFenetre.png',
    imgMobile:
      'https://hellio.com/hubfs/simulateur/img_success2/porteFenetre-m.png',
    template: 'normal',
    paragraph: paragraphPAC,
    simulation: true,
  },

  primeAutresTravaux: {
    h1: 'Autre travaux',
    imgDesktop: 'https://hellio.com/hubfs/simulateur/img_success2/autre.png',
    imgMobile: 'https://hellio.com/hubfs/simulateur/img_success2/autre-m.png',
    template: 'comment',
    h3: 'Votre inscription est bien prise en compte. Pour donner suite à votre projet, un appel avec un conseiller Hellio est nécessaire. <br/>Nous vous rappelerons dans les plus brefs délais. ',
    simulation: true,
  },
  Thermostat: {
    h1: 'Changement de Thermostat',
    h2: 'Installation d’un Thermostat',
    imgDesktop:
      'https://www.hellio.com/hubfs/simulateur/img_success2/thermostat.png',
    imgMobile:
      'https://www.hellio.com/hubfs/simulateur/img_success2/thermostat.png',
    template: 'comment',
    // h3: ({ answers }) =>
    //   answers['Q53.V2'] === 'Collectif' &&
    //   answers['Q2.V2'] === 'Appartement' ? (
    //     <div>
    //       <p className={'text-xblue !mb-4'}>
    //         Votre situation actuelle ne permet pas de bénéficier de la prime.
    //         Pour être éligible à la prime CEE Thermostat,{' '}
    //         <b>
    //           votre logement doit être équipé d'un système de chauffage
    //           individuel
    //         </b>
    //         .
    //       </p>
    //       <p className={'text-xblue !mb-4'}>
    //         Pour une rénovation énergétique complète de votre copropriété.
    //         Inscrivez votre syndic sur{' '}
    //         <a
    //           href="copropriéte.hellio.com"
    //           className={'text-xblue'}
    //           target={'_blank'}
    //         >
    //           copropriete.hellio.com
    //         </a>
    //         .
    //       </p>
    //     </div>
    //   ) : (
    //     <div className={'leading-none flex flex-col gap-4'}>
    //       <p className={'text-[20px] text-xblue'}>
    //         Votre installation peut être pris en charge
    //       </p>
    //       <p className={'text-[28px] text-xblue font-bold'}>jusqu’a 100%</p>
    //     </div>
    //   ),
    // comment: (
    //   <div>
    //     <p className={'text-[10px]'}>
    //       Contactez nos experts pour avoir un devis <b>financé jusqu'a 100%</b>
    //     </p>
    //     <p className={'text-[10px]'}>
    //       * Sous réserve du respect des{' '}
    //       <a
    //         className={'text-xdefault'}
    //         target={'_blank'}
    //         href="https://faq.hellio.com/coup-de-pouce-thermostat"
    //         rel="noreferrer"
    //       >
    //         règles du coup de pouce
    //       </a>
    //       .
    //     </p>
    //   </div>
    // ),
    comment: <PrimeSuspendue />,
    simulation: true,
    showPrime: false,
  },

  IsolationCopropriété: {
    ...CoproShare,
  },

  ChauffageCopropriété: {
    ...CoproShare,
  },

  AuditEnergetiqueCopropriété: {
    ...CoproShare,
  },

  RenovationGlobaleCopropriété: {
    ...CoproShare,
  },

  photovoltaïqueCopropriété: {
    ...CoproShare,
  },
};

export default dataSuccess;
