import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export default function Resume({ footer, subtitle, title, className }) {
  return (
    <div
      className={clsx('rounded bg-white p-4 lg:p-6 leading-none', className)}
    >
      <h2 className={'text-[14px] lg:text-[12px] font-medium'}>{title}</h2>
      <div
        className={
          'flex flex-col justify-between gap-4 mt-4 lg:flex-row lg:mt-4'
        }
      >
        {footer.map((item, index) => (
          <div key={index} className={'flex gap-2 items-center'}>
            <img className={'w-8 h-8'} src={item.icon} alt="" />
            <div className={'flex gap-2 flex-row lg:flex-col'}>
              <h4 className={'font-normal text-nowrap text-[14px]'}>
                {item.title}
              </h4>
              <p className={'text-[14px] lg:text-[16px] font-medium'}>
                {item.subtitle}
              </p>
            </div>
          </div>
        ))}
        <div></div>
      </div>
    </div>
  );
}

Resume.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.element,
  }),
  footer: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      icon: PropTypes.element,
    }),
  ),
};
