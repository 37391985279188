import React from 'react';
import { useSimulation } from 'providers/SimulationProvider';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import infosBlack from 'images/icons/infos-black.svg';
import InfoTooltip from '../../InfoTooltip';
import Reminder from './Reminder';

export default function BandeauPrixSolaire({
  autoconsomationPrime,
  className,
  installationPrice,
  rentFee,
  codePromoPrime,
  rentPrice,
  totalCashPrice,
  panneaux,
  selectedGamme,
  prime_autoconsommation_info,
  url_prime_autoconsommation,
}) {
  const { handleCallBtn } = useSimulation();
  // Voir https://helliosolutions.teamwork.com/app/tasks/26761657
  return (
    <div
      className={clsx(
        'flex flex-wrap items-center lg:gap-4 gap-2 leading-none',
        className,
      )}
    >
      <div className={'w-full lg:hidden'}>
        <p className={'text-[16px] font-[500] text-white'}>
          {selectedGamme} · {panneaux} panneaux solaires
        </p>
      </div>
      <div className="grow flex lg:flex-row flex-col items-stretch lg:items-center text-white gap-4">
        <div className="flex flex-row lg:flex-col items-center lg:items-start justify-between lg:justify-start">
          <p className="lg:text-[16px] text-[12px] font-light">
            Coût à l’installation
          </p>
          <p className="font-bold lg:text-[24px] text-[16px]">
            {installationPrice}
          </p>
        </div>
        {autoconsomationPrime && (
          <div
            className={
              'bg-white rounded flex lg:flex-col flex-row px-1 py-1 lg:p-2 justify-between lg:justify-start'
            }
          >
            <p className="lg:text-[16px] text-[12px] font-light text-xorange flex items-center gap-0.5">
              <span>Prime à l’autoconsommation</span>
              {prime_autoconsommation_info && (
                <InfoTooltip
                  className={'bg-black bg-opacity-70'}
                  icon={<img src={infosBlack} className={'w-3 h-3'} alt="" />}
                >
                  <p className={'text-white'}>{prime_autoconsommation_info}</p>
                  {url_prime_autoconsommation && (
                    <a
                      className={'text-white underline'}
                      href={url_prime_autoconsommation}
                      target={'_blank'}
                      rel={'noreferrer'}
                    >
                      En savoir plus
                    </a>
                  )}
                </InfoTooltip>
              )}
            </p>
            <p className="font-bold lg:text-[24px] text-[16px] text-xorange">
              {autoconsomationPrime}
            </p>
          </div>
        )}
        {codePromoPrime && (
          <div
            className={
              'bg-white rounded flex lg:flex-col flex-row px-1 py-1 lg:p-2 justify-between lg:justify-start'
            }
          >
            <p className="lg:text-[16px] text-[12px] font-light text-xorange ">
              <span>Code promo</span>
            </p>
            <p className="font-bold lg:text-[24px] text-[16px] text-xorange">
              {codePromoPrime}
            </p>
          </div>
        )}
        <div className={'flex items-stretch gap-2 lg:grow'}>
          <p className="font-bold text-[32px] pr-6">=</p>
          <p className="font-bold text-[32px]">{totalCashPrice}</p>
          <div className="flex flex-col">
            <p className="text-[16px]">
              <span className={'font-normal'}>ou </span>
              <span className={'font-bold'}>{rentPrice}</span>
            </p>
            <p className="text-[8px] font-light">{rentFee}</p>
          </div>
        </div>
        <Reminder className={'flex-col items-stretch'} />
      </div>
    </div>
  );
}

BandeauPrixSolaire.propTypes = {
  className: PropTypes.string,
  installationPrice: PropTypes.string,
  autoconsomationPrime: PropTypes.string,
  codePromoPrime: PropTypes.string,
  totalCashPrice: PropTypes.string,
  rentPrice: PropTypes.string,
  rentFee: PropTypes.string,
  panneaux: PropTypes.string,
  selectedGamme: PropTypes.string,
  prime_autoconsommation_info: PropTypes.string,
  url_prime_autoconsommation: PropTypes.string,
};
