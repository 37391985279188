import React from 'react';
import PropTypes from 'prop-types';
import * as RadixSlider from '@radix-ui/react-slider';
import clsx from 'clsx';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevronRight.svg';

export default function Slider(props) {
  const {
    className,
    leftMark,
    rightMark,
    'aria-label': ariaLabel,
    ...rest
  } = props;

  return (
    <div className="flex flex-col">
      <RadixSlider.Root
        className={clsx(
          'relative flex items-center select-none w-full touch-none h-5',
          className || '',
          rightMark && leftMark && 'px-5',
        )}
        {...rest}
      >
        <RadixSlider.Track className="relative bg-grand-public-500 flex-grow rounded-full h-3">
          <RadixSlider.Range className="absolude bg-white rounded-full h-full" />
        </RadixSlider.Track>

        <RadixSlider.Thumb
          className="flex cursor-grab items-center justify-center  h-7 w-7 bg-dark-gray-500 shadow-sm text-white rounded-full"
          aria-label={ariaLabel || ''}
        >
          <ChevronLeftIcon width={8} height={8} />
          <ChevronRightIcon width={8} height={8} />
        </RadixSlider.Thumb>
      </RadixSlider.Root>

      {leftMark && rightMark && (
        <div className="flex items-center justify-between">
          {leftMark}
          {rightMark}
        </div>
      )}
    </div>
  );
}

Slider.propTypes = {
  className: PropTypes.string,
  leftMark: PropTypes.element,
  rightMark: PropTypes.element,
  'aria-label': PropTypes.string,
};
