import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import phoneIcon from 'images/icons/phoneIcon.svg';
import EcoNote from './EcoNote';
import DelayInfos from './DelayInfos';
import { formatPriceEUR } from '../../../utils/formatPrice';
import ChoiceOffer from './ChoiceOffer';
import PowerCharacteristics from './PowerCharacteristics';
import PromoCode from './PromoCode';

export default function VerticalRecommandation({
  className,
  activeGamme,
  activeQuantity,
  data,
  gamme,
  offre,
  onGammeChange,
  onQuantityChange,
}) {
  return (
    <div
      className={clsx(
        'flex flex-col items-stretch bg-white rounded p-3',
        className,
      )}
    >
      <h2 className={'text-[12px] font-medium'}>Votre choix de gamme</h2>
      <div className={'grid grid-cols-3 rounded bg-light_orange mt-3'}>
        {Object.keys(data).map((item, index) => {
          const active = item === activeGamme;
          return (
            <button
              onClick={() => onGammeChange(item)}
              key={item}
              className={clsx('rounded-lg border-none py-2 px-4 text-[16px]', {
                'bg-xorange text-white font-medium': active,
                'bg-transparent text-xorange font-light': !active,
              })}
            >
              {item}
            </button>
          );
        })}
      </div>
      <ChoiceOffer
        title={`Votre offre choisie: Panneaux ${data[activeGamme].type_panneau}
              ${data[activeGamme].type_onduleur}`}
        className={'mt-3'}
        descriptions={[
          {
            icon: gamme.url_image_origine_panneau,
            title: gamme.origine_panneau,
          },
          {
            icon: gamme.url_image_garantie,
            title: gamme.type_garantie,
          },
          {
            icon: phoneIcon,
            title: gamme.type_monitoring,
          },
        ]}
        durations={[
          {
            icon: gamme.url_image_panneau,
            years: gamme.garantie_panneau,
          },
          {
            icon: gamme.url_image_onduleur,
            years: gamme.garantie_onduleur,
          },
          // {
          //   icon: coffretImage,
          //   years: 10,
          // },
        ]}
      />
      <DelayInfos
        rentability={`${offre.seuil_rentabilite} ans`}
        lifecycle={`${offre?.duree_de_vie} ans`}
        installationWarranty={`${gamme?.garantie_panneau} ans`}
        url_indice_de_rentabilite={gamme.url_indice_de_rentabilite}
        indice_de_rentabilite_info={gamme.indice_de_rentabilite_info}
        className={'mt-4'}
      />
      <div className={'flex flex-nowrap gap-2 mt-3'}>
        <div className={'grow flex flex-col justify-center'}>
          <img
            src={offre.url_image_maison}
            className={'w-full h-auto'}
            alt=""
          />
          <EcoNote
            infos={'Qu’est-ce que l’Éco-note Hellio ?'}
            vertical
            active={gamme.econote_hellio}
            econote_hellio_info={gamme.econote_hellio_info}
            url_econote_hellio_info={gamme.url_econote_hellio_info}
          />
        </div>
        <div>
          <PowerCharacteristics
            maxPower={`${offre.puissance} kWc`}
            surface={`${offre.surface} m²`}
            annualSaved={`${formatPriceEUR(offre?.economies_max_annuelle)}`}
            bonus25Years={
              offre
                ? `${formatPriceEUR(offre.gain[0])} - ${formatPriceEUR(offre.gain[1])}`
                : ''
            }
          />
        </div>
      </div>
      <PromoCode className={'items-center mt-3'} />
    </div>
  );
}

VerticalRecommandation.propTypes = {
  className: PropTypes.string,
  offre: PropTypes.object,
  gamme: PropTypes.object,
  data: PropTypes.object,
  activeGamme: PropTypes.string,
  activeQuantity: PropTypes.number,
  onGammeChange: PropTypes.func,
  onQuantityChange: PropTypes.func,
};
