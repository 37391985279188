import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export default function BandeauSuccess(props) {
  const { children, className, ...rest } = props;
  return (
    <div
      className={clsx(
        'w-full text-white bg-grand-public-500 lg:px-8 p-4',
        className || '',
      )}
      {...rest}
    >
      {children}
    </div>
  );
}

BandeauSuccess.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
};
