import { useState, useEffect } from 'react';

const cache = new Map();

function useFetch(queryKey, queryFn) {
  return useQuery(queryKey, queryFn);
}

/**
 * Custom hook for fetching data with caching.
 * @param {Function} asyncFunction - The async function that fetches data.
 * @param {string} cacheKey - A unique key to identify the cache entry.
 * @returns {{ isLoading: boolean, data: any, error: any }}
 */
function useQuery(cacheKey, asyncFunction) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);

      // Check cache first
      if (cache.has(cacheKey)) {
        setData(cache.get(cacheKey));
        setIsLoading(false);
        return;
      }

      try {
        const result = await asyncFunction();
        if (isMounted) {
          cache.set(cacheKey, result); // Cache the result
          setData(result);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          setError(err);
          setData(null);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [asyncFunction, cacheKey]);

  return { isLoading, data, error };
}

export default useFetch;
