import React from 'react';
import PropTypes from 'prop-types';
import Amount from 'components/Amount';
import InfoHoverBlock from 'components/success/InfoHoverBlock';

class AccompagnateurRenoBlock extends React.Component {
  render() {
    const { renoTagOffer, isRenoGlobale, isAudit, mar } = this.props;

    const InfoComponent = () => {
      return (
        <div className="renoItem ">
          <label className="d-flex jcsb">
            <h2 className="whitespace-nowrap">
              - <Amount value={mar} />
            </h2>
          </label>
        </div>
      );
    };

    if (renoTagOffer && isRenoGlobale && !isAudit) {
      return (
        <>
          <div className="flex flex-row justify-between items-center mb-2 pb-2">
            <InfoHoverBlock
              data={this.props.APIresult}
              success={this.props.success}
              item="PrimeAccompagnateur"
              showTooltip
            ></InfoHoverBlock>
            <div className="success-item-right !p-0 !m-0" style={{ border: 0 }}>
              <InfoComponent />
            </div>
          </div>
          <div className="hr-reno my-2 " />
        </>
      );
    }
    return null;
  }
}

AccompagnateurRenoBlock.propTypes = {
  mar: PropTypes.number.isRequired,
  isAudit: PropTypes.bool.isRequired,
  isRenoGlobale: PropTypes.bool.isRequired,
  renoTagOffer: PropTypes.object.isRequired,
  APIresult: PropTypes.object.isRequired,
  success: PropTypes.string.isRequired,
};

export default AccompagnateurRenoBlock;
