import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export default function OfferResultBlock(props) {
  const {
    className = 'bg-bleu-hellio-500',
    label,
    unit,
    value,
    ...rest
  } = props;

  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-md text-white',
        className || '',
      )}
      {...rest}
    >
      <div className="flex flex-col items-center px-8 py-4">
        <p className="text-sm font-medium capitalize">{label}</p>

        <h4 className="flex items-baseline gap-x-1">
          <span className="text-2xl font-bold">{value.toLocaleString()}</span>
          <span className="text-base font-medium">{unit}</span>
        </h4>
      </div>
    </div>
  );
}

OfferResultBlock.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.number,
};
