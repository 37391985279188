import PropTypes from 'prop-types';
import React, { useState } from 'react';

export default function Switch(props) {
  const {
    defaultChecked = false,
    checked = undefined,
    onChange,
    ...rest
  } = props;
  const [isChecked, setIsChecked] = useState(defaultChecked ?? checked);

  const toggle = () => {
    setIsChecked((prev) => !prev);
    onChange(!checked);
  };

  return (
    <button
      className="cursor-pointer bg-transparent border-none p-0"
      onClick={toggle}
    >
      <label
        htmlFor={`inline-block switcher cursor-pointer`}
        className="flex w-[44px] h-[22px] rounded-full bg-gray-400 p-[2px] cursor-pointer"
        {...rest}
      >
        <span
          className="flex relative w-[1.125rem] h-[1.125rem] bg-white rounded-full left-[21px] "
          style={{
            left: `${checked ? '22px' : '0px'}`,
            transition: 'left 0.2s',
          }}
        ></span>
      </label>
    </button>
  );
}

Switch.propTypes = {
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};
