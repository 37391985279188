import React from 'react';
import Reminder from './Reminder';
import PropTypes from 'prop-types';

export default function BandeauPrixBatterieVirtuelle(props) {
  const {
    gamme,
    panneaux,
    monthly,
    offre,
    monthlyPowerPrice,
    price: installationPrice,
  } = props.data;

  return (
    <div className="lg:flex lg:flex-row">
      <div className={'w-full lg:hidden'}>
        <p className={'text-[16px] font-[500] text-white'}>
          {gamme.nom} · {panneaux} panneaux solaires
        </p>
      </div>

      <div className="lg:items-center lg:flex lg:justify-center lg:flex-grow">
        <div className="flex flex-col gap-y-2 mt-2 mb-6 lg:flex-row lg:m-0 lg:gap-x-6 lg:items-center lg:flex-grow-0">
          <div className="flex flex-row gap-y-4 items-center justify-between lg:items-start lg:justify-start ">
            <p className="text-xs lg:hidden">Coût à l’installation</p>
            <h4 className="text-base lg:text-[32px] font-bold">
              {installationPrice.toLocaleString()} €
            </h4>
          </div>

          <div className="hidden lg:flex lg:items-stretch lg:flex-col ">
            <h4>
              ou <strong>{monthly} € / mois*</strong>
            </h4>

            <p className="text-[10px] font-light">{offre.info_loyer}</p>
          </div>

          <span className="hidden text-[32px] font-bold lg:block">+</span>

          <div className="flex flex-row items-center justify-between rounded-[4px] bg-white text-grand-public-500 px-1 py-1 lg:px-3 lg:py-2 lg:flex-col-reverse lg:items-start lg:justify-start">
            <p>Forfait mensuel batterie virtuelle</p>
            <h4 className="text-base font-bold lg:text-2xl">
              {monthlyPowerPrice}€ / mois
            </h4>
          </div>

          <div className="flex-col  lg:flex-col lg:hidden">
            <div className="flex items-baseline gap-2">
              <h4 className="text-2xl">
                <strong>= {installationPrice.toLocaleString()} €</strong>
              </h4>
              <strong className="">ou {monthly} € / mois* </strong>
            </div>

            <p className="text-[10px] font-light">{offre.info_loyer}</p>
          </div>
        </div>
      </div>

      <Reminder className={'flex-col items-stretch'} />
    </div>
  );
}

BandeauPrixBatterieVirtuelle.propTypes = {
  data: PropTypes.object,
};
