import React from 'react';

export function PrimeSuspendue() {
  return (
    <div className="!text-sm  text-xblue py-4">
      <h4 className="font-bold !text-xblue text-sm">La Prime Coup De Pouce est suspendue</h4>

      <p className="!my-4">
        La prime Coup De Pouce est suspendue par le gouvernement depuis le 22
        novembre 2024.
      </p>

      <p>
        En attendant une nouvelle version plus sûre (fraudes détectées), nous
        vous tiendrons informés dès qu'elle sera accessible début 2025.
      </p>
    </div>
  );
}
