/* eslint-disable react/prop-types */
import React from 'react';
import InfoTooltip from 'components/InfoTooltip';

const MA_PRIME_RENOV_INFO = `est une aide publique dont le montant varie en fonction des revenus et du type de travaux.`;
const PRIME_HELLIO_INFO = `est versée par les fournisseurs d'énergie, obligés de financer des opérations d'économies d'énergie.`;
const MA_PRIME_ACCOMPAGNATEUR_INFO =
  "La Prime MAR est une aide publique donée par l'Anah sur l'accompagnement de Mon Accompagnateur Rénov'";

class InfoHoverBlock extends React.Component {
  getTooltipContent = () => {
    const { item } = this.props;

    switch (item) {
      case 'Prime1':
      case 'PrimeReno':
        return (
          <>
            <strong>La prime Hellio</strong> : {PRIME_HELLIO_INFO}
          </>
        );
      case 'Prime2':
        return (
          <>
            <strong>MaPrimeRénov&apos;</strong> : {MA_PRIME_RENOV_INFO}
          </>
        );
      case 'Energy':
        return (
          <>
            Votre étiquette énergétique est une estimation fournie par Go rénove
            grâce à votre adresse, vous pouvez{' '}
            <a
              href="https://particulier.gorenove.fr/"
              target="_blank"
              className="text-white"
              rel="noreferrer"
            >
              voir votre diagnostic ici
            </a>
            .
          </>
        );
      case 'CodePromo':
        return <strong>Code Promo</strong>;

      case 'PrimeAccompagnateur':
        return (
          <>
            <strong>La Prime Accompagnement</strong> :{' '}
            {MA_PRIME_ACCOMPAGNATEUR_INFO}
          </>
        );
      default:
        return null;
    }
  };

  render() {
    const { data, item, showTooltip } = this.props;
    const MaPrimeRenov = data['Montant MPR unitaire (€)'];
    const PrimeHellio = data['Montant prime CEE unitaire (€)'];

    if (MaPrimeRenov == null && PrimeHellio == null && item === 'PrimeReno') {
      return <div></div>;
    }

    let primeName;

    switch (item) {
      case 'Prime1':
      case 'PrimeReno':
        primeName = 'Prime Hellio';
        break;
      case 'PrimeAccompagnateur':
        primeName = 'Prime Accompagnement';
        break;
      default:
        primeName = "MaPrimeRénov'";
    }

    return (
      <div style={{ maxWidth: 'fit-content' }}>
        <div className="info-block-container">
          <div className="is-bold mb-10">
            <span className="prime-name">{primeName}</span>
            {showTooltip && (
              <InfoTooltip>{this.getTooltipContent()}</InfoTooltip>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default InfoHoverBlock;
