import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import OfferResultBlock from './OfferResultBlock';
import { Slider } from 'ui';

export default function PowerPriceCalculator(props) {
  let { puissance, onSliderChange } = props;

  puissance = Object.entries(puissance).map(([key, value], index) => {
    return {
      key: (index + 1) * 100,
      power: parseInt(key),
      price: parseInt(value),
    };
  });

  const [currentPower, setCurrentPower] = useState(puissance[1]);

  useEffect(() => {
    onSliderChange(currentPower);
  }, []);

  const onChange = ([value]) => {
    const p = puissance.filter((item) => item.key === value)[0];
    setCurrentPower(p);
    onSliderChange(p);
  };

  const Mark = ({ label }) => (
    <p className="text-xs text-dark-gray-300">{label} kwh</p>
  );

  Mark.propTypes = {
    label: PropTypes.string,
  };

  const range = {
    min: puissance[0].key,
    max: puissance[puissance.length - 1].key,
  };

  return (
    <div className="flex flex-col gap-y-6">
      <h3 className="!text-sm font-medium">
        Forfait stockage et batterie virtuelle
      </h3>

      <div className="flex flex-col gap-y-4">
        <div className="min-w-[374px] m-auto">
          <Slider
            step={100}
            leftMark={<Mark label={range.min} />}
            defaultValue={[currentPower.key]}
            rightMark={
              <Mark
                label={puissance[puissance.length - 1].power.toLocaleString()}
              />
            }
            min={range.min}
            max={range.max}
            onValueChange={onChange}
          />
        </div>

        <div className="flex items-center justify-center gap-x-4">
          <OfferResultBlock
            unit={'Kwh'}
            label={'Puissance'}
            value={currentPower.power}
            className="bg-bleu-hellio-500 min-w-44"
          />
          <OfferResultBlock
            unit={'€'}
            label={'Forfait par mois'}
            value={currentPower.price}
            className="bg-grand-public-500 min-w-48"
          />
        </div>
      </div>
    </div>
  );
}

PowerPriceCalculator.propTypes = {
  puissance: PropTypes.object,
  onSliderChange: PropTypes.func,
};
