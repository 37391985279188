import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Switch from 'ui/Switch';

export default function OfferSwitcher(props) {
  const { title, defaultKey, className, keys, onChange } = props;
  const [checked, setChecked] = useState(true);
  const [leftChoice, righChoice] = keys;
  const [selected, setSelected] = useState(leftChoice);

  const style = {
    backgroundColor: selected.color,
    color: selected.color,
  };

  const onCheckedChange = (checked) => {
    if (!checked) {
      setSelected(righChoice);
      onChange(righChoice);
    } else {
      setSelected(leftChoice);
      onChange(leftChoice);
    }

    setChecked(checked);
  };

  return (
    <div
      className={clsx(
        'bg-white p-4 rounded-lg space-y-2 shadow-sm',
        className || '',
      )}
    >
      <h3 className="text-2xl">{title}</h3>

      <div className="flex">
        <button
          onClick={() => onCheckedChange(true)}
          style={{ color: checked ? selected.color : '' }}
          className="bg-transparent border-none p-0 text-base cursor-pointer pr-2 text-industriels-500 "
        >
          {leftChoice.label}
        </button>

        <Switch checked={checked} onChange={onCheckedChange} style={style} />

        <button
          onClick={() => onCheckedChange(false)}
          style={{ color: !checked ? selected.color : '' }}
          className="bg-transparent border-none p-0 text-base cursor-pointer pl-2 text-industriels-500"
        >
          {righChoice.label}
        </button>
      </div>
    </div>
  );
}

OfferSwitcher.propTypes = {
  title: PropTypes.string,
  defaultKey: PropTypes.string,
  keys: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  className: PropTypes.string,
};
